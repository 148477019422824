import React, {useEffect, useState} from 'react';
import {useAuth} from "../../nux/niam/auth";
import NotLoggedIn from "./NotLoggedIn";
import {useTranslation} from "react-i18next";
import {CollectorSessionBrief, CollectorSessionResponse} from "../../client/AccountInsightsTypes";
import {useNavigate} from "react-router-dom";
import NuxSearchField from "../../nux/components/buttons/NuxSearchField";
import {
    Alert,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl, FormControlLabel,
    InputLabel,
    List, NativeSelect,
    Pagination,
    Paper,
    Select, Switch,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'
import {PersonDetails} from "./components/PersonDetails";
import {CreateNewAccountInsightSessionDialog} from "./components/CreateNewAccountInsightSessionDialog";
import {ConsentListItem} from "./components/ConsentListItem";
import {useAccountInsightClient} from "../../client/AccountInsightClient";
import CheckIcon from "@mui/icons-material/Check";
import FimentoFrame from "../../components/FimentoFrame";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';


const MainPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation()
    const {authenticated, accessToken} = useAuth()
    const {allAccountInsightSessions, accountInsightInitiate, removeSession, getAccountInsight, restart, archiveSession, unarchiveSession} = useAccountInsightClient()
    const [response, setResponse] = useState<CollectorSessionResponse | null>(null);
    const [searchResults, setSearchResults] = useState<CollectorSessionBrief[] | null>(null);
    const [searchParameter, setSearchParameter] = useState('');
    const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
    const [message, setMessage] = useState<string | undefined>(undefined)
    const [visualisationLink, setVisualisationLink] = useState<string | undefined>(undefined)
    const [currentPage, setCurrentPage] = useState(1);
    const [sessionsPerPage, setSessionsPerPage] = useState(10);
    const [totalSessions, setTotalSessions] = useState(0);
    const [showArchived, setShowArchived] = useState<boolean | null>(null);

    const createConsentRequest = async (person: PersonDetails):Promise<void> => {
        console.log("createConsentRequest", person)
        return new Promise((resolve, reject) => accountInsightInitiate(person.personId, person.name, person.email, person.shouldSendEmail)
            .then(value => {
                resolve();
                setSearchParameter('')
                handleOnSearch('', null)
            })
            .catch(reason => reject(reason)))
    }

    const handleOnSearch = (searchValue: string, status: string | null) => {
        const fetchAllAccountInsightSessions = async (authenticated: boolean) => {
            if (!authenticated)
                return;

            console.log('Show archived:', showArchived);

            const offset = (currentPage - 1) * sessionsPerPage;
            try {
                const body = await allAccountInsightSessions(searchValue, sessionsPerPage, offset, status, showArchived);
                setResponse(body);
                setTotalSessions(body.total);
                setSearchResults(body.list);
            } catch (e) {
                console.error("Failed to communicate with backend!", e);
                setMessage("Failed to communicate with backend. Try again later!");
            }
        };
        try {
            fetchAllAccountInsightSessions(authenticated)
                .catch(reason => console.error("Failed to fetch events", reason));
        } catch (e) {
            console.error("Failed to fetch events", e);
        }
    };

    useEffect(() => {
        handleOnSearch(searchParameter, selectedStatus);
    }, [searchParameter, currentPage, sessionsPerPage, selectedStatus, showArchived]);

    useEffect(() => {
        if (!authenticated)
            navigate("/")
    }, [authenticated]);

    useEffect(() => {
        if (message)
            setTimeout(() => {
                setMessage(undefined)
            }, 5000)
    }, [message]);

    if (!authenticated)
        return <NotLoggedIn/>

    if (searchResults == null) {
        handleOnSearch(searchParameter, selectedStatus);
    }

    const copyConsentUrlToClipboard = (clientRedirectUrl: string) => {
        if (!clientRedirectUrl)
            throw new Error('No consent url!');
        if (!navigator.clipboard)
            throw new Error('Browser don\'t have support for native clipboard!');
        const fetch = async () => {
            await navigator.clipboard.writeText(clientRedirectUrl);
        }
        fetch();
    }

    const showDetails = async (id: string) => {
        //navigate(detailsPageNavigationPath(id));
        const collectorSession = await getAccountInsight(id);
        setVisualisationLink(collectorSession.visualisationLink)
    }

    const restartCollection = async (id: string) => {
        //navigate(detailsPageNavigationPath(id));
        const collectorSession = await restart(id);
        handleOnSearch('', null);
    }

    const removeSessionClick = async (id: string) => {
        await removeSession(id)
        handleOnSearch('', null);
    }

    const handleArchiveSession = async (id: string) => {
        try {
            await archiveSession(id);
            handleOnSearch('', null); // Refresh the session list
        } catch (error) {
            console.error("Failed to archive session", error);
        }
    };

    const handleUnarchiveSession = async (id: string) => {
        try {
            await unarchiveSession(id);
            handleOnSearch('', null); // Refresh the session list
        } catch (error) {
            console.error("Failed to unarchive session", error);
        }
    };

    return (
        <>
            {message && (
                <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                    {message}
                </Alert>
            )}
            <Dialog onClose={() => setVisualisationLink(undefined)} open={visualisationLink != null} fullScreen={true}>
                <DialogTitle>{t('visualization')}</DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setVisualisationLink(undefined)}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent>
                    {visualisationLink && <FimentoFrame visualisationLink={visualisationLink} header={false} />}
                </DialogContent>
            </Dialog>

            <Paper sx={{ padding: '10px', mb: '20px' }}>
                <Grid container spacing={2} columnSpacing={8}>
                    <Grid xs={6}>
                        <NuxSearchField
                            onSearch={(searchText) => {
                                setSearchParameter(searchText);
                                setCurrentPage(1);
                            }}
                        />
                    </Grid>
                    <Grid xs={6} sx={{ mt: '7px' }}>
                        <CreateNewAccountInsightSessionDialog onCreateNewAccountInsightSession={createConsentRequest} />
                    </Grid>
                </Grid>

                <Grid xs={12} sx={{ mt: 3, mb: 1 }}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" sx={{
                            marginRight: 1,
                            paddingTop: '2px'
                        }}>
                            {t('status')}:
                        </Typography>
                        <FormControl size="small" sx={{ minWidth: 280 }}>
                            <Select
                                id="status-select"
                                value={selectedStatus || "ALL"}
                                onChange={(e) => {
                                    setSelectedStatus(e.target.value === "ALL" ? null : e.target.value);
                                    setCurrentPage(1);
                                }}
                            >
                                <MenuItem value={"ALL"}>{t('showAll')}</MenuItem>
                                <MenuItem value={"INITIALIZING"}>{t('initializing')}</MenuItem>
                                <MenuItem value={"WAITING_FOR_CONSENT"}>{t('waitingForConsent')}</MenuItem>
                                <MenuItem value={"COLLECTION_IN_PROGRESS"}>{t('collectingAndCalculating')}</MenuItem>
                                <MenuItem value={"FETCHING_INSIGHTS"}>{t('fetchingInsights')}</MenuItem>
                                <MenuItem value={"INSIGHTS_COLLECTED"}>{t('completed')}</MenuItem>
                                <MenuItem value={"ERROR"}>{t('failed')}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControlLabel
                            label={`${t('showArchived')}:`}
                            labelPlacement="start"
                            control={
                                <Switch
                                    checked={!!showArchived}
                                    onChange={(e) => {
                                        setShowArchived(e.target.checked);
                                        setCurrentPage(1);
                                    }}
                                />
                            }
                            sx={{ ml: 4 }}
                        />
                    </Box>
                </Grid>
            </Paper>

            {searchResults && searchResults.length === 0 && <div className="mt-4">{t('emptySessionList')}</div>}
            {searchResults && searchResults.length !== 0 && (
                <>
                    <List
                        sx={{
                            width: '100%',
                            bgcolor: '#F7F7F7',
                            paddingTop: '10px',
                            paddingRight: '10px',
                        }}
                    >
                        {searchResults.map((row) => (
                            <ConsentListItem
                                key={row.id}
                                row={row}
                                onRemoveSession={removeSessionClick}
                                onShowDetails={showDetails}
                                onRestart={restartCollection}
                                onCopyConsentUrlToClipboard={copyConsentUrlToClipboard}
                                onArchiveSession={handleArchiveSession}
                                onUnarchiveSession={handleUnarchiveSession}
                            />
                        ))}
                    </List>

                    <Grid container justifyContent="space-between" alignItems="center" sx={{ marginTop: '10px' }}>
                        <Grid xs={12} sm={4}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="subtitle1" sx={{
                                    marginRight: 1,
                                    paddingTop: '2px'
                                }}>
                                    {t('sessionsPerPage')}:
                                </Typography>
                                <FormControl size="small">
                                    <Select
                                        id="sessions-per-page-select"
                                        value={sessionsPerPage}
                                        onChange={(e) => {
                                            setSessionsPerPage(Number(e.target.value));
                                            setCurrentPage(1);
                                        }}
                                    >
                                        <MenuItem value={10}>10</MenuItem>
                                        <MenuItem value={20}>20</MenuItem>
                                        <MenuItem value={50}>50</MenuItem>
                                        <MenuItem value={100}>100</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid xs={12} sm={8}>
                            <Pagination
                                count={Math.ceil(totalSessions / sessionsPerPage)}
                                page={currentPage}
                                onChange={(event, value) => setCurrentPage(value)}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
}

export default MainPage
